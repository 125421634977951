export const cedenarValidURLs = [    
    "/webcedenar/principal/consola-componentes",
    "/webcedenar/principal/arbol-tarifario",
    "/webcedenar/principal/opcion-tarifaria",
    "/webcedenar/principal/reliquidaciones",
    "/webcedenar/principal/publicar-tarifas",
    "/webcedenar/principal/alumbrado-publico",
    "/webcedenar/principal/validacion-archivos",
    "/webcedenar/principal/validacion-parametros",
    "/webcedenar/principal/mercados",    
    "/webcedenar/principal/contratos-compra",
    "/webcedenar/principal/contratos-venta",
    "/webcedenar/principal/actores-financieros",
    "/webcedenar/principal/balance-energetico", 
    "/webcedenar/principal/informe-comercializacion",
    "/webcedenar/principal/reportes-sui",
    "/webcedenar/principal/liquidacion-str",
    "/webcedenar/autogeneradores/usuarios",
    "/webcedenar/autogeneradores/parametrizacion-precios-pactados",
    "/webcedenar/autogeneradores/parametrizacion-conceptos",
    "/webcedenar/autogeneradores/parametrizacion-ciclos",  
    "/webcedenar/autogeneradores/parametrizacion-causas-no-lectura",  
    "/webcedenar/autogeneradores/control-archivos",
    "/webcedenar/autogeneradores/consumos-cliente",
    "/webcedenar/autogeneradores/liquidacion-consumos",
    "/webcedenar/autogeneradores/reporte-usuario",
    "/webcedenar/autogeneradores/exportar-liquidacion",
    "/webcedenar/autogeneradores/informe-facturacion",
    "/webcedenar/autogeneradores/tendencia-facturacion",
    "/webcedenar/autogeneradores/consultas/panel-principal",
    "/webcedenar/autogeneradores/consultas/descargar-reporte",
    "/webcedenar/autogeneradores/consultas/estado-pago-excedentes",
    "/webcedenar/autogeneradores/consultas/comportamiento-estimado",
    "/login",    
]