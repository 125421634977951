import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../auth';

import ListAltIcon from '@material-ui/icons/ListAlt';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TrainRounded } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

const GENERARCO_DISC = [5.78, 6.13, 5.78, 5.89, 7.24, 7.4, 7.24, 7.24, 7.4]

export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date() 
    let indexationChangeDate = new Date(2022,9,0)
    let indexationLimitDate = new Date(2023,11,0)
    let esprodInternalChangeDate = new Date(2022,10,0)
    const [contractDialog, setContractDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [contractList, setContractList] = useState([])
       
    const [contractNumber, setContractNumber] = useState(0)
    const [seller, setSeller] = useState("")
    const [detail, setDetail] = useState("")
    const [market, setMarket] = useState("")
    const [calcType, setCalcType] = useState("")
    const [contractType, setContractType] = useState("")
    const [baseValue, setBaseValue] = useState(0)
    const [indexationType, setIndexationType] = useState(null)
    const [dbIpc, setDbIpc] = useState(0)
    const [dbIpp, setDbIpp] = useState(0)
    const [dbFg, setDbFg] = useState(0)

    const [indexedValue, setIndexedValue] = useState(0)
    const [actualIpp, setActualIpp] = useState(0); 
    const [baseIpp, setBaseIpp] = useState(0);     
    const [initialDate, setInitialDate] = useState(actualDate)   
    const [endDate, setEndDate] = useState(actualDate)
    const [active, setActive] = useState(true)
    const [displayAll, setDisplayAll] = useState(false)
    let [activeContracts, setActiveContracts] = useState([])
    let [allContracts, setAllContracts] = useState([])
    const [cere, setCere] = useState(0)

    const handleContractDialogOpen = () => {
        setContractDialog(true);
      };
    
    const handleContractDialogClose = () => {
        setContractDialog(false);
    };
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedContract = (idx) => {
        setContractNumber(contractList[idx].contract_id)    
        setSeller(contractList[idx].seller)
        setDetail(contractList[idx].detail)
        setMarket(contractList[idx].market_type)
        setCalcType(contractList[idx].calc_type)
        setIndexationType(contractList[idx].indexation_var)
        setBaseValue(contractList[idx].initial_value)
        setActualIpp(contractList[idx].indexation_var === 'IPP' ? dbIpp : 
        contractList[idx].indexation_var === 'IPC' ? dbIpc :
        null)
        setIndexedValue(contractList[idx].indexed_value)            
        setBaseIpp(contractList[idx].initial_ipp)
        setInitialDate(contractList[idx].begin_date)
        setEndDate(contractList[idx].end_date)
        setContractType(contractList[idx].contract_type)
        setActive(contractList[idx].active)
    }
 
    const handleContractUpload = () => {        
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        if ( !(calcType && baseValue) ){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {


            let opts = 
                calcType === 'INDEXADO' ?
                {
                    'contract_id': contractNumber,
                    'seller': seller,
                    'detail': detail,
                    'market_type': market,
                    'calc_type': calcType,
                    'initial_value': baseValue,
                    'indexation_var': indexationType,
                    'initial_ipp': baseIpp,
                    'indexed_value': indexedValue,
                    'begin_date': initialDate,
                    'end_date':  endDate,
                    'active': active,
                    'username': props.username,
                    'contract_type': contractType
                }
                :
                {
                    'contract_id': contractNumber,
                    'seller': seller,
                    'detail': detail,
                    'market_type': market,
                    'calc_type': calcType,
                    'initial_value': baseValue,   
                    'indexation_var': null,
                    'initial_ipp': null,
                    'indexed_value': null,                 
                    'begin_date': initialDate,
                    'end_date': endDate,
                    'active': active,
                    'username': props.username,
                    'contract_type': contractType
                }
              authFetch(
                "/api/update-contracts" + "?type=sell",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                    handleFeedbackDialogOpen()
                    handleContractDialogClose()
                    authFetch("/api/check-contracts?username=" + String(props.username) + "&type=sell").then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.contracts){           
                                setAllContracts(response.contracts)
                                let activeCts = response.contracts.flatMap((contract) => contract.active ? contract : [])
                                setActiveContracts(activeCts)                    
                                setContractList(displayAll ? response.contracts : activeCts)                                
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showContractDialog = () => (
        <Dialog
            open={contractDialog}
            onClose={handleContractDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes del contrato"}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Contrato:</Typography>
                        </Grid>
                        <Grid item>
                            <ListAltIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={contractNumber}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Vendedor:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={seller}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                disabled                                                                                          
                            >
                               
                                
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Detalle:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={detail}                             
                                placeholder={"Detalle del contrato."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                multiline                                       
                                onChange={(e) => {setDetail(e.target.value);}}                       
                            />
                                
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Mercado:</Typography>
                        </Grid>
                        <Grid item>
                            <LocationCityIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={market === 'R' ? 'Regulado' : 'No regulado'}
                                
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}    
                                disabled                                                      
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de contrato:</Typography>
                        </Grid>
                        <Grid item>
                            <AssessmentIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={contractType} 
                                placeholder={"Seleccione el tipo de contrato."}                               
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => setContractType(e.target.value)}
                            >
                                <MenuItem value={"BILATERAL"} className={classes.formItem}>Bilateral</MenuItem>
                                <MenuItem value={"POR SUBASTA"} className={classes.formItem}>Por subasta - 2</MenuItem>
                                <MenuItem value={"POR SUBASTA 3"} className={classes.formItem}>Por subasta - 3</MenuItem>
                            </TextField>
                                                       
                        </Grid>
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>                                                 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de cálculo:</Typography>
                        </Grid>
                        <Grid item>
                            <AssessmentIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={calcType} 
                                placeholder={"Seleccione el tipo de cálculo."}                               
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => setCalcType(e.target.value)}
                            >
                                <MenuItem value={"INDEXADO"} className={classes.formItem}>Indexado</MenuItem>
                                <MenuItem value={"FIJO"} className={classes.formItem}>Fijo</MenuItem>
                            </TextField>
                                                       
                        </Grid>                                                                                                                       
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Valor base (k) $:</Typography>
                        </Grid>
                        <Grid item>
                            <MonetizationOnIcon/>
                        </Grid>
                        <Grid item xs={9}>
                             <TextField
                                type="number"
                                value={baseValue}
                                placeholder={"Ingrese el valor base del kWh."}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setBaseValue(e.target.value); 
                                    setIndexedValue( baseIpp ? (actualIpp*e.target.value/baseIpp).toFixed(2) : 0); }}                            
                            />                           
                        </Grid> 
                        {
                            calcType === 'FIJO' ?
                            null
                            :                             
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Variable de indexación:</Typography>
                            </Grid>
                            <Grid item>
                                <MoreVertIcon/>
                            </Grid>
                            <Grid item xs={9}>                   
                                <TextField
                                    value={indexationType} 
                                    placeholder={"Seleccione la variable de indexación."}                               
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText,                                       
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}                                                                                         
                                    select
                                    onChange = {(e) => {setIndexationType(e.target.value);
                                                        setActualIpp(e.target.value === 'IPC' ? dbIpc : dbIpp)}}
                                >
                                    <MenuItem value={"IPC"} className={classes.formItem}>IPC</MenuItem>
                                    <MenuItem value={"IPP"} className={classes.formItem}>IPP</MenuItem>
                                </TextField>
                                                        
                            </Grid> 
                            </Fragment>                            
                        }
                        {
                            calcType === 'FIJO' || !indexationType ? 
                            null
                            :
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">'{'Valor base ' + indexationType + ':'}</Typography>
                            </Grid>
                            <Grid item>
                                <ShowChartIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={baseIpp}
                                    placeholder={"Ingrese el valor base del " + indexationType}
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}   
                                    onChange={(e) => {setBaseIpp(e.target.value); 
                                        setIndexedValue( baseValue ? (actualIpp*baseValue/e.target.value).toFixed(2) : 0);}}                            
                                />                           
                            </Grid> 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">{'Valor periodo actual ' + indexationType + ':'}</Typography>
                            </Grid>
                            <Grid item>
                                <ShowChartIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={actualIpp}                                
                                    InputProps={{                  
                                        readOnly: true,                  
                                        className: classes.formDefined,
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}  
                                    disabled                                                       
                                />                           
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Costo indexado kWh:</Typography>
                            </Grid>
                            <Grid item>
                                <MonetizationOnIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={baseIpp && baseValue ? (actualIpp*baseValue/baseIpp).toFixed(2) : 0}                                
                                    InputProps={{                  
                                        readOnly: true,             
                                        className: classes.formDefined,     
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}   
                                    disabled                                                      
                                />                           
                            </Grid>
                            </Fragment>
                        }
                       
                    </Grid>                                       
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={2}>                                                                                                                     
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha inicio contrato:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}
                            openTo="month"
                            format="MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                            views={["year","month"]}                                
                            value={initialDate}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={setInitialDate}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha terminación contrato:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}
                            openTo="month"
                            format="MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                            views={["year","month"]}                                
                            value={endDate}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={setEndDate}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Estado:</Typography>
                    </Grid>
                    <Grid item >
                        <CheckCircleIcon/>
                    </Grid>
                    <Grid item xs={10}>
                        <Switch
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                            color="primary"                                                 
                        />
                        {active? 'Activo':'Inactivo'}
                    </Grid> 
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleContractUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleContractDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Contratos de venta";
        authFetch("/api/check-contracts?username=" + String(props.username) + "&type=sell").then(response => {
            if (response.status === 422){
                logout()
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.contracts){
                    setAllContracts(response.contracts)
                    let activeCts = response.contracts.flatMap((contract) => contract.active ? contract : [])
                    setActiveContracts(activeCts)
                    setContractList(activeCts)                    
                    //setCmcList(response.cmc)
                }
            })
        authFetch("/api/check-parameters?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&market=" + props.market + "&version=" + props.tariffCalcVersion + "&calcType=").then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.parameters_state){
                ////console.log('Response', response)
                let ippValues = response.parameters_state[6].Calc_Values
                let ipcValues = response.parameters_state[7].Calc_Values
                let fgValues = response.parameters_state[response.parameters_state.length-1].Calc_Values
                setDbIpp(ippValues[ippValues.length-1])     
                setDbIpc(ipcValues[ipcValues.length-1])
                // VALOR TEMPORAL DE DESCUENTO A CONTRATO GENERARCO
                setDbFg(fgValues[0])                          
            }
        })

        authFetch("/api/check-inputs?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&market=" + props.market + "&version=" + props.tariffCalcVersion + "&calcType=").then(response => {
            if (response.status === 422){
                logout()
                return null
            }
            return response.json()
        }).then(response => {
            if (response && response.files_state){            
                let filterObj = response.files_state.filter((fs) => fs.DB_Name === 'trsm')[0]
                setCere( filterObj && 'Calc_Values' in filterObj ? filterObj['Calc_Values'][1] : 0 )
            }
        })
    }, [props.periodDate, props.market,  props.tariffCalcVersion])

    return (
        <div className={classes.root}>
            {showContractDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de contratos de venta:</Typography>
                            <div style={{margin: 10}}>
                                    Mostrar todos
                                    <Checkbox
                                        color="primary"
                                        checked={displayAll}
                                        onChange={(e) => {setDisplayAll(e.target.checked); e.target.checked ? setContractList(allContracts):setContractList(activeContracts);}}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                            </div>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "5%"}}>Contrato</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "5%"}}>Vendedor</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Detalle</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "5%"}}>Mercado</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "6%"}}>Tipo contrato</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "7%"}}>Tipo cálculo</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "6%"}}>Valor base($)</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "6%"}}>Valor indexado($)</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "6%"}}>Valor + CERE($)</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "5%"}}>Var. indexación</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "8%"}}>IPC/IPP Periodo actual</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "8%"}}>IPC/IPP Base</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Fecha inicio contrato</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Fecha final contrato</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {contractList.map((contract,idx) => (
                                            <StyledTableRow key={idx} onClick={() => {
                                                                                    updateValuesWithSelectedContract(idx); 
                                                                                    handleContractDialogOpen();}}>
                                                <StyledTableCell align="center">{contract.contract_id}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.seller}</StyledTableCell>
                                                <StyledTableCell align="center" >{contract.detail}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.market_type === 'R' ? 'Reg.' : 'No reg.'}</StyledTableCell>
                                                <StyledTableCell align="center">{
                                                    contract.contract_type ?
                                                        contract.contract_type === 'BILATERAL' ? contract.contract_type
                                                        :
                                                        contract.contract_type.includes('3') ? 
                                                        'POR SUBASTA - 3'
                                                        :
                                                        'POR SUBASTA - 2'
                                                    :
                                                    '-'
                                                }</StyledTableCell>
                                                <StyledTableCell align="center">{contract.calc_type}</StyledTableCell>
                                                <StyledTableCell align="center" >{contract.initial_value}</StyledTableCell>
                                                <StyledTableCell align="center" >{
                                                    props.periodDate < indexationChangeDate || props.periodDate > indexationLimitDate?
                                                        contract.initial_value && contract.initial_ipp ? 
                                                        (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                contract.indexation_var === 'IPC' ? dbIpc :
                                                                                0)/contract.initial_ipp)).toFixed(2) 
                                                        :
                                                        null
                                                    :
                                                        ['35342', '35671', '37940', '50523', '43755', '49730','59218','47076', '47540','54946'].includes(String(contract.contract_id)) ? 
                                                            contract.contract_id === 35342 ? 
                                                                props.periodDate < esprodInternalChangeDate ?                                                            
                                                                (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                    contract.indexation_var === 'IPC' ? dbIpc :
                                                                                    0)/contract.initial_ipp) - 7.8).toFixed(2)
                                                                :
                                                                ((contract.initial_value*(174.12/contract.initial_ipp) - 7.8)*(Math.min(dbIpp/174.12, dbIpc/123.51))).toFixed(2)
                                                            :
                                                            contract.contract_id === 35671 ?
                                                            (contract.initial_value*(147.65/contract.initial_ipp)*Math.min(dbIpp/147.65, 1.1)).toFixed(2)
                                                            :
                                                            contract.contract_id === 37940 ?
                                                            (contract.initial_value*(dbIpc/99.31)).toFixed(2)
                                                            :
                                                            contract.contract_id === 50523 ?
                                                            (contract.initial_value*(147.65/contract.initial_ipp)*Math.min(dbIpp/147.65, 1.1)).toFixed(2)
                                                            :
                                                            contract.contract_id === 43755 ?
                                                            (contract.initial_value*(147.65/contract.initial_ipp)*Math.min(176.17/147.65, 1.1)*Math.min(dbIpp/176.17, 1.1)).toFixed(2)
                                                            :
                                                            contract.contract_id === 54946 ?
                                                                props.periodDate.getMonth() == 0 ?
                                                                270.24
                                                                :
                                                                contract.initial_value && contract.initial_ipp ? 
                                                                (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                        contract.indexation_var === 'IPC' ? dbIpc :
                                                                                        0)/contract.initial_ipp)).toFixed(2)
                                                                :
                                                                '-' 
                                                            :
                                                            contract.contract_id === 49730 ?
                                                                props.periodDate.getMonth() + 1 < 10 ? 
                                                                    '-'
                                                                :
                                                                    contract.initial_value && contract.initial_ipp ? 
                                                                    (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                            contract.indexation_var === 'IPC' ? dbIpc :
                                                                                            0)/contract.initial_ipp)).toFixed(2)
                                                                    :
                                                                    '-'
                                                            :
                                                            contract.contract_id === 59218 ?
                                                                props.periodDate.getMonth() + 1 < 10 ? 
                                                                    '-'
                                                                :
                                                                contract.initial_value && contract.initial_ipp ? 
                                                                (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                        contract.indexation_var === 'IPC' ? dbIpc :
                                                                                        0)/contract.initial_ipp)).toFixed(2)
                                                                :
                                                                '-'                                                            
                                                            :
                                                            contract.contract_id === 47076 ?
                                                                props.periodDate.getMonth() + 1 < 10 ? 
                                                                    '-'
                                                                :
                                                                contract.initial_value && contract.initial_ipp ? 
                                                                (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                        contract.indexation_var === 'IPC' ? dbIpc :
                                                                                        0)/contract.initial_ipp)).toFixed(2)
                                                                :
                                                                '-'
                                                            :
                                                            contract.contract_id === 47540 ?
                                                            (contract.initial_value*(147.65/contract.initial_ipp)*Math.min(176.17/147.65, 1.1)*Math.min(dbIpp/176.17, 1.1)).toFixed(2)
                                                            :
                                                            '-'
                                                        :
                                                        contract.initial_value && contract.initial_ipp ? 
                                                            (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                    contract.indexation_var === 'IPC' ? dbIpc :
                                                                                    0)/contract.initial_ipp)).toFixed(2) 
                                                            :
                                                                null
                                                }</StyledTableCell>
                                                <StyledTableCell align="center" >{
                                                        contract.contract_type !== 'BILATERAL' ?
                                                            contract.initial_value && contract.initial_ipp ? 
                                                            (contract.initial_value*((contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                    contract.indexation_var === 'IPC' ? dbIpc :
                                                                                    0)/contract.initial_ipp)+cere).toFixed(2) 
                                                            :
                                                            null
                                                        :
                                                        '-'
                                                        }</StyledTableCell>  

                                                <StyledTableCell align="center" >{contract.indexation_var}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.indexation_var === 'IPP' ? dbIpp : 
                                                                                contract.indexation_var === 'IPC' ? dbIpc :
                                                                                null}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.initial_ipp}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.begin_date ? new Date(contract.begin_date).toISOString().slice(0,7) : null}</StyledTableCell>
                                                <StyledTableCell align="center">{contract.end_date ?  new Date(contract.end_date).toISOString().slice(0,7) : null}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            {/* <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{ handleContractDialogOpen();}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>       */}
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}